<!-- components/AppModal.vue -->
<script lang="ts" setup>
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    visible: boolean
    size?: string
  }>(),
  {
    size: 'lg',
  }
)

const emit = defineEmits<{
  'update:visible': [payload: boolean]
}>()

const close = () => {
  emit('update:visible', false)
}
</script>

<template>
  <BModal
    v-model="props.visible"
    @update:model-value="(v) => emit('update:visible', v)"
    :size="props.size"
    hide-header
    hide-footer
    body-class="p-0"
    content-class="rounded-5 overflow-hidden"
    centered="true"
  >
    <div class="modal-body custom-modal-body" v-bind="$attrs">
      <slot v-bind="{ close }" />
    </div>
  </BModal>
</template>
